<template>
  <div></div>
</template>
<script>
import { POPUP_AVIS_OPEN } from '../constantes.js';
export default {
  components: {},
  mixins: [],
  data() {
    return {};
  },
  computed: {
    c1() {
      return true;
    }
  },
  methods: {
    async m1() {}
  },
  mounted() {
    const page = this.$route.query.page_courante;
    const url = this.$route.query.url_drupal;

    const params = {
      source: 'drupal',
      pageName: page,
      redirectionUrl: url,
      redirection: true
    };
    this.emitter.emit(POPUP_AVIS_OPEN, params);
  }
};
</script>
<style lang="scss" scoped></style>
